<template>
  <div class="container py-5">
    <p class="fw-bold">2022 第59屆廣州國際美博會</p>
    <p>時間：2022 03/10-12</p>
    <p>地點：廣州·中國進出口商品交易會展館</p>
    <p>展位號：12.2 / H12A</p>
    <p>
      第59屆中國（廣州）國際美博會將在廣州中國進出口商品交易會琶洲展館隆重啟幕。
      <br>
      銘伊恆美入駐12.2館H12A展位，精彩集結，矚目全場。
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_03_1.jpg"
    />
  </div>
</template>
<script>
export default {};
</script>
